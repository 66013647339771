<template>
  <v-img :src="imageUrl ? imageUrl : image"
         eager
         max-height="400"
         class="align-end"
  >
    <v-card-title
      :class="image || imageUrl ? 'white--text font-weight-bold' : `${mainThemeColor}--text`"
      :style="imageUrl || image ? '-webkit-text-stroke: 1px black' : ''"
    >
      {{ title }}
    </v-card-title>
  </v-img>
</template>

<script>
export default {
  name: "ImageForCardWithTitle",
  props: ['image', 'imageUrl', 'title', 'mainThemeColor']
}
</script>

<style scoped>

</style>
